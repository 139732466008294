import React, { useState, useEffect } from 'react';
import './BackToTopButton.css'

function BackToTopButton() {
  const [showButton, setShowButton] = useState(false);

  // This effect will toggle the visibility of the button based on scroll position
  useEffect(() => {
    const handleScroll = () => {
      // Show button when page is scrolled more than 300 pixels
      setShowButton(window.scrollY > 300);
    };

    // Register the event listener for window scroll
    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener from the window
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Function to scroll to top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // For smooth scrolling
    });
  };

  return (
    showButton && (
      <button onClick={scrollToTop} className="back-to-top">
        ↑
      </button>
    )
  );
}

export default BackToTopButton;
