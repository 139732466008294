import React, { useState } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import HeroSection from './components/HeroSection'
import Navbar from './components/Navbar'
import ContactSection from './components/ContactSection';
import CasestudiesSection from './components/CasestudiesSection';
import PortfolioSection from './components/PortfolioSection';
import Footer from './components/Footer';
import BackToTopButton from './components/BackToTopButton';

function App() {
  return (
    <div id="root" width='100vw'>
      <Navbar />
      <HeroSection />
      <CasestudiesSection />
      <PortfolioSection />
      <ContactSection />
      <Footer />
      <BackToTopButton />
    </div>
  );
}

export default App;
