import React from 'react';
import { Container } from 'react-bootstrap';
import './Footer.css'

function Footer() {
  return (
    <div className="footer">
      <p style={{ textAlign: 'center' }}>© 2024 Proximal Technologies</p>
    </div>
  );
}

export default Footer;
