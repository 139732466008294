import React from 'react';
import { Container, Row, Col, Carousel, Card, CarouselItem } from 'react-bootstrap';
import './Shared.css';
import './CasestudiesSection.css';
import dynoimage from '../images/dyno-image.png';
import nanoimage from '../images/nano-image.png';
import fieldimage from '../images/field-image.png';
import axleimage from '../images/axle-image.png';
import dyno from '../documents/Dynamometer-Case-Study.pdf'; 
import field from '../documents/Field-Uniformity-Case-Study.pdf'; 
import nano from '../documents/Nanomaterial-Case-Study.pdf'; 
import axle from '../documents/Axle-Counter-Case-Study.pdf'; 

function CasestudiesSection() {
  const caseStudies = [
    {
      title: 'Dynamometer Case Study',
      image: dynoimage,
      link: dyno,
      text: 'Proximal Technologies developed a custom software package using LabVIEW for a major European luxury automaker to precisely control an electric vehicle dynamometer within an automotive EMC lab. Click the Download button to learn more!'
    },
    {
      title: 'Field Uniformity Case Study',
      image: fieldimage,
      link: field,
      text: 'Proximal Technologies developed a turnkey field uniformity test system for a leading EMC testing company, which involved integrating state-of-the-art hardware for electromagnetic field generation and measurement with custom LabVIEW software to create a seamless and user-friendly solution. Click the Download button to learn more!'
    },
    {
      title: 'Nanomaterial Case Study',
      image: nanoimage,
      link: nano,
      text: 'Proximal Technologies engineered a sophisticated control system for a nanomaterial manufacturing plant, focusing on monitoring and controlling numerous pumps, valves, and sensors. Click the Download button to learn more!'
    },
    {
      title: 'Axle Counter Case Study',
      image: axleimage,
      link: axle,
      text: 'Proximal Technologies solved the challenge of monitoring and analyzing high-frequency signals from a PXI-based railway data collection system, aimed at deducing train and axle counts for performance analysis and preventive maintenance. Click the Download button to learn more!'
    }
  ];

  return (
    <Container fluid id='casestudies-section' className='full-height-section casestudies-bg' style={{ marginTop: '50px', paddingBottom: '100px' }}>
      <Row className='justify-content-center'>
        <Col xs={11} md={10} lg={9}>
          <h2 style={{ color: 'white' }}>Case Studies</h2>
          <p style={{ color: 'white' }} className="justify-text">
            Check out these case studies from previous projects we've completed for customers around the world!
          </p>
          <Carousel>
            {caseStudies.map((study, index) => (
              <CarouselItem>
                <Card key={index} style={{ backgroundColor: 'lightgrey' }}>
                  <Card.Body>
                    <Row>
                      <Col lg={4} md={6} className="justify-text">      
                        <Card.Title>{study.title}</Card.Title>              
                        <Card.Text>
                          {study.text}
                        </Card.Text>
                      </Col>
                      <Col lg={8} md={6} className="justify-right">
                        <a href={study.link} download className="btn btn-primary d-block w-100" style={{marginBottom: '10px'}}>Download Case Study</a>
                        <img src={study.image} alt={study.title} className='img-fluid' style={{marginBottom: '35px'}}/>
                      </Col>                    
                    </Row>
                  </Card.Body>
                </Card>
              </CarouselItem>
            ))}
          </Carousel>
        </Col>
      </Row>
    </Container>
  );
}

export default CasestudiesSection;
