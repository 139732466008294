import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import Logo from '../proximal_logo_simple.svg';
import './Navbar.css'; // Make sure to adjust the path as necessary

function Navigation({ onNavClick }) {
  return (
    <Navbar variant="dark" expand="lg" className="navbar-background">
      <Navbar.Brand href="#home" style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
        <img
          src={Logo}
          height="80"   
          className="d-inline-block align-top"
          alt="Proximal Technologies logo"
          style={{ marginLeft: '10px' }}
        />
        {'Proximal Technologies'}
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ms-auto">
          <Nav.Link href="#casestudies-section">Case Studies</Nav.Link>
          <Nav.Link href='#portfolio-section'>Portfolio</Nav.Link>
          <Nav.Link href="#contact-section">Contact</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Navigation;
