import React from 'react';
import { Container, Row, Col, Card, CardTitle, CardText} from 'react-bootstrap';
import './Shared.css'
import './ContactSection.css'

function ContactSection() {
  return (
    <Container fluid id='contact-section' className='full-height-section contact-bg'>
      <Row className='justify-content-center'>
        <Col style={{ color: 'white' }} xs={11} md={10} lg={9}>
          <h2>Contact</h2>
          <p className='justify-text'>
            We are eager to hear from you! Please use the email and phone number below to contact us 
            so that we can set up a time to discuss your project requirements and solution options!
          </p>
          <p>
            Email: sales@proximalcorp.com
          </p>
          <p>
            Phone: +1(512)554-3948
          </p>
          <p>
            &nbsp;
          </p>
          <p>
            &nbsp;
          </p>
          <p>
            &nbsp;
          </p>
          <p>
            &nbsp;
          </p>
        </Col>
      </Row> 
    </Container>
  );
}

export default ContactSection;
