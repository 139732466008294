import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './Shared.css';
import displayImage from '../images/display.webp';

function HeroSection({ onNavClick }) {
    return (
        <Container id='hero-section' className="full-height-section hero-container" fluid>
            
            {/* Left section with the paragraph and cards stacked */}
            <Row className="align-items-start">
                <Col xs={12} lg={8} className="content-section">
                    <p className='justify-text hero-description'>
                        Welcome to Proximal Technologies! We provide custom solutions for electronic test, measurement, automation, and industrial control applications. Founded in 2013, Proximal Technologies serves customers all around the globe. Our experienced staff can help you rapidly identify project requirements, develop custom solutions, and deploy systems to the locations of your choice.
                    </p>

                    <Card className="expertise-card">
                        <Card.Body>
                            <Card.Title>Software Expertise</Card.Title>
                            <Card.Text>
                                <ul className="expertise-list">
                                    <li>Python</li>
                                    <li>LabVIEW (CLA on staff)</li>
                                    <li>C#/.NET, C++</li>
                                    <li>JavaScript, Node.js, React, HTML, CSS</li>
                                    <li>OpenAI API and other AI models</li>
                                </ul>
                            </Card.Text>
                        </Card.Body>
                    </Card>

                    <Card className="expertise-card">
                        <Card.Body>
                            <Card.Title>Hardware Expertise</Card.Title>
                            <Card.Text>
                                <ul className="expertise-list">
                                    <li>Most NI hardware including PXI, cRIO, cDAQ, sbRIO, and others</li>
                                    <li>ETS-Lindgren field probes, EMCenter, and modular instruments</li>
                                    <li>Major instrument brands: Keysight, Rohde & Schwarz, Tektronix</li>
                                </ul>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                
                {/* Right section with a smaller image */}
                <Col xs={12} lg={4} className="d-flex justify-content-center img-col">
                    <img src={displayImage} alt='Display' className='img-fluid rounded shadow-lg smaller-image' />
                </Col>
            </Row>
        </Container>
    );
}

export default HeroSection;
