import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './Shared.css';
import './PortfolioSection.css';
import pcbimage from '../images/pcb-image.png';
import pcb from '../documents/PCBtest.zip'; 
import nanoimage from '../images/nano-image.png';
import fieldimage from '../images/field-image.png';
import field from '../documents/Field-Uniformity-Case-Study.pdf'; 
import nano from '../documents/Nanomaterial-Case-Study.pdf'; 

function PortfolioSection() {
  const demos = [
    // {
    //   title: 'Python Test Executive',
    //   image: fieldimage,
    //   link: field,
    // },
    {
      title: 'PCB Test Demo',
      image: pcbimage,
      link: pcb,
    }
    // {
    //   title: 'Nanomaterial',
    //   image: nanoimage,
    //   link: nano,
    // }
  ];

  return (
    <Container id='portfolio-section' className='full-height-section portfolio-section' style={{ marginTop: '50px', paddingBottom: '100px' }}>
      <Row>
        <Col>
          <h2>Portfolio</h2>
          {/* <p className="justify-text">
            Take a look at our portfolio of demo projects that highlight specific development skills and solutions! 
            Click on one of the tiles below to download the complete code. 
          </p> */}
          <p className="justify-text">
            Take a look at our demo project that highlight specific development skills and solutions! 
            Click the tile below to download the complete code. 
          </p>
          <div className="portfolio-overlap-layout">
            {demos.map((item, index) => (
              <a href={item.link} className='portfolio-item' key={index}>
                <Card key={index} className='portfolio-card' style={{ backgroundImage: `url(${item.image})` }}>
                  <Card.Body>
                    <a href={item.link} className="btn btn-primary">{item.title}</a>
                  </Card.Body>
                </Card>
              </a>
            ))}
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default PortfolioSection;
